// export default {
//   // BASE_URL: "https://devshopapi.whitecoretechnology.com",
//   BASE_URL: "https://localhost:7253",
// }

// export default {
//   BASE_URL: "https://devshopapi.whitecoretechnology.com",
// }

export default {
  BASE_URL: "https://demoshopapi.whitecoretechnology.com",        // demo
}


// export default {
//   // BASE_URL: "shopapi.whitecoretechnology.com",    // live
//   BASE_URL: "https://shopapi.whitecoretechnology.com",    // live
// }


// https://devshopapi.whitecoretechnology.com/
// https://demoshopapi.whitecoretechnology.com/